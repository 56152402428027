body {
  margin: 0;
  font-family: 'Open Sans', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: #f5f5f5;
  overflow-x: hidden;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}
@-moz-document url-prefix() {
  div button#filter-type {
    padding-top: 14px;
  }
}

#iconHotspotMenuButtonId span {
  min-width: 10px !important;
  padding: 0 3px;
}

.fontTabular {
  white-space: unset !important;
  line-break: anywhere;
  line-height: 19px !important;
  display: flex !important;
  font-size: 11px;
  /* display: inline-flex !important; */
  align-items: center !important;
}

.themeHeader {
  display: flex !important;
  align-items: center !important;
  padding-left: 5px !important;
  padding-right: 5px !important;
  background: #005d83;
  color: #fff;
  font-size: 14px;
}
